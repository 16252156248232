/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.togglenavigation,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/****** layout-large.less 2013-1-16 *******/
#home {
  top: -180px;
  width: 300px;
  right: 100px;
  padding: 15px;
  transition: top 0.3s 0.3s ease-out;
}
.show-content #home {
  top: 0;
}
.cb-goto-top-visible + .header #home {
  top: -180px;
  transition: top 0.3s ease-out;
}
@media (max-width: 1480px) {
  #home {
    width: 200px;
    top: -130px;
  }
  .cb-goto-top-visible + .header #home {
    top: -130px;
  }
}
@media (max-width: 1230px) {
  #home {
    width: 160px;
    right: 30px;
    padding-right: 10px;
    padding-left: 10px;
    top: -110px;
  }
  .cb-goto-top-visible + .header #home {
    top: -110px;
  }
}
.navigation {
  margin: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  transition: background 0.5s;
  text-align: center;
}
.cb-goto-top-visible + .header .navigation {
  background: #eae1c8;
  transition: background 0.4s;
}
#work ~ .header > .navigation {
  margin-top: 50px;
}
#edit .header {
  background-color: #aaa;
}
#head {
  margin-top: 90px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  font-size: 36px;
  font-size: 3.6rem;
}
h1 {
  font-size: 36px;
  font-size: 3.6rem;
}
.footer {
  position: relative;
}
@media (min-width: 1323px) {
  .footer:after {
    position: absolute;
    left: 20px;
    content: '';
    width: 200px;
    height: 160px;
    top: -160px;
    background: url(/images/roessli.svg) no-repeat bottom;
    background-size: 100%;
  }
}
@media (min-width: 1870px) {
  .footer:after {
    width: 420px;
    height: 342px;
    top: -342px;
  }
}
#services {
  margin-top: -32px;
  float: right;
}
@media (min-width: 1130px) {
  #social {
    margin-bottom: 0;
  }
}
.service_voucher {
  left: -16px;
}
.menu {
  color: #fff;
  transition: color 0.218s, padding 0.218s;
  padding: 2em 0.6em 0.3em;
}
.cb-goto-top-visible + .header .menu {
  padding-top: 1em;
}
.menu:hover,
.menu:focus {
  transition: none;
}
.sub2 > .item > .menu:hover,
.sub2 > .item > .menu:focus {
  /*
  https://basecamp.com/2703448/projects/10901343/todos/328800522
  */
  color: #000;
}
.cb-goto-top-visible + .header .menu {
  color: #000;
}
.cb-goto-top-visible + .header .menu:hover,
.cb-goto-top-visible + .header .menu:focus {
  color: #fff;
}
div.sub2 {
  display: inline-block;
  float: none;
  text-align: left;
}
@media (max-width: 1225px) {
  div.sub2 {
    margin-left: -110px;
  }
}
div.sub3 {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  background: #eae1c8;
}
.item:hover > .sub3,
#edit.cbe--edit-navigation .path > .sub3 {
  display: block;
}
div.sub3 .menu {
  color: #000;
  padding-top: 0.4em !important;
  padding-bottom: 0.4em !important;
}
div.sub3 .menu.menu.menu:hover,
div.sub3 .menu.menu.menu:focus {
  color: #835331;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.96078431%;
  margin-left: 1.96078431%;
}
.area .part,
.area > .grid table {
  margin-right: 1.96078431%;
  margin-left: 1.96078431%;
  width: 96.07843137%;
}
.area .tiny {
  width: 46.07843137%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.92156863%;
  margin-left: 3.92156863%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92.15686275%;
}
.area > .slim .tiny {
  width: 92.15686275%;
}
.cb-layout1 .area {
  width: 100%;
}
.cb-layout1 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area h2,
.cb-layout1 .area .foot {
  margin-right: 1.96078431%;
  margin-left: 1.96078431%;
}
.cb-layout1 .area .part,
.cb-layout1 .area > .grid table {
  margin-right: 1.96078431%;
  margin-left: 1.96078431%;
  width: 96.07843137%;
}
.cb-layout1 .area .tiny {
  width: 46.07843137%;
}
.cb-layout1 .area > .slim {
  width: 33.33333333%;
}
.cb-layout1 .area > .slim h2,
.cb-layout1 .area > .slim .foot,
.cb-layout1 .area > .slim .part,
.cb-layout1 .area > .slim.grid table {
  margin-right: 5.88235294%;
  margin-left: 5.88235294%;
}
.cb-layout1 .area > .slim .part,
.cb-layout1 .area > .slim.grid table {
  width: 88.23529412%;
}
.cb-layout1 .area > .slim .tiny {
  width: 88.23529412%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.area.area {
  width: 104.08163265%;
  margin-left: -2.04081633%;
}
div.area.north {
  right: 100px;
  width: 230px;
}
@media (max-width: 1230px) {
  div.area.north {
    right: 30px;
  }
  div.area.north .sb__footer-link {
    margin-top: 6px;
  }
}
.fold + .fold {
  clear: left;
}
.unit {
  margin-top: 30px;
  margin-bottom: 30px;
}
.wide .head {
  margin-right: 1.96078431%;
  margin-left: 1.96078431%;
}
.unit h2 {
  font-size: 21px;
  font-size: 2.1rem;
}
.seam .tiny {
  clear: left;
}
.seam .show.tiny {
  float: right;
  clear: none;
}
.cb-layout1 .slim.slim {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.4;
  width: 29.41176471%;
  margin-right: 1.96078431%;
  margin-left: 1.96078431%;
}
.cb-layout1 .slim.slim .part,
.cb-layout1 .slim.slim.grid table,
.cb-layout1 .slim.slim .foot {
  width: 93.33333333%;
}
.cb-layout1 .slim.slim h2,
.cb-layout1 .slim.slim .part,
.cb-layout1 .slim.slim.grid table,
.cb-layout1 .slim.slim .foot {
  margin-right: 3.33333333%;
  margin-left: 3.33333333%;
}
/*
limit to wide units with one image
*/
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -400px;
  width: 800px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #eae1c8;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #eae1c8;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #dccda3;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 16px;
  font-size: 1.6rem;
  display: inline-block;
  padding: 0.1em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  border-radius: 2px;
  background-color: #ccc;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(153, 153, 153, 0.75);
  line-height: 2.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  background-color: #d4d4d4;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #bfbfbf #a6a6a6 #a6a6a6 #bfbfbf;
  background-color: #b3b3b3;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/*# sourceMappingURL=./screen-large.css.map */